// Imports
// ------------
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import { Jacket } from './styles';
import 'swiper/css';

// Component
// ------------
const GallerySlider = ({ data }) => {
    return (
        <Jacket>
            <Swiper
                loop={true}
                loopedSlides={data.length}
                // autoHeight={true}
                draggable={true}
                grabCursor={false}
                allowTouchMove={true}

                slidesPerView={1.2}
                spaceBetween={12}
                centeredSlides={true}
            >
                {data.map((slide, i) => (
                    <SwiperSlide key={`slide_` + i}>
                        <GatsbyImage image={slide.data} alt={slide.alt} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Jacket>
    );
}

export default observer(GallerySlider);