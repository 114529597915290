// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Fieldset } from '@tackl';
import { pStyles, spanStyles } from '@tackl-type';

// Exports
// ------------
export const Jacket = styled(Fieldset)(props => css`
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    min-width: 15rem;
    margin-bottom: 1.2rem;

    ${breakup.medium` margin-bottom: 2.4rem; `}

    &:after {
        content: '';
        position: absolute;
        bottom: 0;

        height: 1px;
        width: 100%;
        background: ${props.isFocus ? props.theme.bc2 : props.theme.bc2};
    }
`);

export const Label = styled.label(
    (p) => css`
        ${pStyles}

        font-size: 1.3rem !important;
        font-weight: 500;
        text-transform: uppercase;
        color: ${p.isFocus ? p.theme.bc1 : p.theme.bc5};

        pointer-events: none;
        transition: all .4s ${p.theme.bezzy};
        transform: translateY(${p.isFocus ? `0rem` : `3rem`});

        width: 80%;
    `
);


export const SelectLabelButton = styled.div(props => css`
    position: absolute;
    top: 0;
    z-index: 2;

    width: 100%;
    height: 100%;
    background: transparent;
    padding: 1.2rem 0;

    line-height: 160%;
    font-size: 1.6rem;
`);

export const SelectLabels = styled.div(props => css`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 1.2rem 0;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;

        height: 1px;
        width: 100%;
        background: ${props.isFocus ? props.theme.bc2 : props.theme.bc2};
    }

    em {
        ${spanStyles}
        text-transform: uppercase;
        font-family: ${props.theme.heading};
        font-weight: 500;
        color: ${props.theme.bc1};
    }

    span {
        &:first-child {
            ${pStyles}
            font-size: 1.3rem !important;
            text-transform: uppercase;
            color: ${props.theme.white};
        }

        &:last-child {
            z-index: 12;

            ${pStyles}
            font-size: 1.3rem !important;
            text-transform: uppercase;
            font-family: ${props.theme.heading};
            font-weight: 500;
            color: ${props.theme.bc2};
        }
    }
`);

export const DropdownList = styled.div(props => css`
    position: absolute;
    top: calc(6rem);
    left: 0;
    width: 100%;
    border: 1px solid ${props.theme.bc2};
    border-radius: 0.6rem;
    background-color: ${props.theme.bc3};
    box-shadow: 0 0 12px ${props.theme.black};
    z-index: 50;
    display: ${props.isVisible ? `block` : `none`};
`);

export const DropdownItem = styled.div(props => css`
    position: relative;
    display: flex;
    width: 100%;
    padding: 1.2rem 2.4rem;
    overflow: hidden;

    ${spanStyles}

    &:first-child {
        border-top-left-radius: 0.6rem;
    }

    &:last-child {
        border-bottom-left-radius: 0.6rem;
    }

    &:not(:last-child)::after {
        content: '';
        position: absolute;
        bottom: 0; left: 0; right: 0;

        width: 100%;
        border-bottom: 1px solid ${props.theme.bc2};
        opacity: .24;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0; left: 0; bottom: 0;
        width: 0%;
        height: 100%;

        transition: all .6s ${props.theme.bezzy};
        background: ${props.theme.bc2};
        opacity: .12;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }

    ${props.active && css`
        &:before {
            width: 1.2rem;
            opacity: 1;
            background: ${props.theme.bc1};
        }

        &:hover {
            pointer-events: none;
        }
    `}
`);