// Imports
// ------------
import React from 'react';
import BookingForm from './Form';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------------
import { Jacket, ImagesJacket, Image, Topographic } from './styles';

// Component
// ------------
const DogsBookingMobile = ({ images, dates, packages }) => {
    return (
        <Jacket padBottom>
            <Row isExpanded isCollapsed>
                <Column small={12} medium={6}>
                    
                    <ImagesJacket>
                        <Image imageOne>
                            <GatsbyImage image={images[0].data} alt={images[0].alt} />
                        </Image>
                        <Image imageTwo>
                            <GatsbyImage image={images[1].data} alt={images[1].alt} />
                        </Image>
                        <Image imageThree>
                            <GatsbyImage image={images[2].data} alt={images[2].alt} />
                        </Image>
                        <Image imageFour>
                            <GatsbyImage image={images[3].data} alt={images[3].alt} />
                        </Image>
                        <Image imageFive>
                            <GatsbyImage image={images[4].data} alt={images[4].alt} />
                        </Image>
                        <Topographic />
                    </ImagesJacket>

                </Column>
                <Column small={12} medium={5}>
                    <BookingForm dates={dates} packages={packages} />
                </Column>
            </Row>
        </Jacket>
    );
}

export default DogsBookingMobile;