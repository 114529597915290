// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import Topo from '@images/ps-topo-bg.png';

// Exports
// ------------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 6;

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0; left: 0; right: 0; bottom: 0;

        background: linear-gradient(0deg, rgba(13, 12, 8, 0) 0%, #0D0C08 38.37%);
    }
`;


export const ImagesJacket = styled.div`
    position: relative;
    min-height: 60vh;
`;

export const Image = styled.div((props) => css`
    position: absolute;
    will-change: transform;

    width: 100%;
    height: 100%;

    ${props.imageOne && css`
        z-index: 1;
        top: 2vh;
        width: 50vw;
        transform: translate(-20%, 0%) rotate(-4deg);

        ${breakup.large` width: 20vw; `}
    `}

    ${props.imageTwo && css`
        z-index: 4;
        top: 2vh;
        width: 50vw;
        transform: translate(100%, -20%) rotate(10deg);

        ${breakup.large` width: 20vw; `}
    `}

    ${props.imageThree && css`
        z-index: 2;
        top: 2vh;
        width: 50vw;
        transform: translate(0%, 80%) rotate(-3deg);

        ${breakup.large` width: 20vw; `}
    `}

    ${props.imageFour && css`
        z-index: 2;
        top: 2vh;
        width: 50vw;
        transform: translate(-25%, 30%) rotate(-3deg);

        ${breakup.large` width: 16vw; `}
    `}

    ${props.imageFive && css`
        z-index: 1;
        top: 2vh;
        width: 50vw;
        transform: translate(0%, 70%) rotate(8deg);

        ${breakup.large` width: 25vw; `}
    `}
`);


export const Topographic = styled.div((p) => css`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;

    display: block;
    width: 0%;
    height: 100%;
    overflow: hidden;
    background: url(${Topo}) repeat;
`);