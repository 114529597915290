// Imports
// ------------
import React, { useState } from 'react';
import SwiperCore, { Controller, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import { Jacket, ThumbsJacket, Next, Prev } from './styles';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/thumbs";

// Swiper
// ------
SwiperCore.use([Controller, Navigation, Thumbs]);

// Component
// ------------
const GallerySlider = ({ data, thumbData }) => {
    // Swiper instance
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [controlledSwiper, setControlledSwiper] = useState(null);

    return (
        <Jacket>
            <Swiper
                loop={true}
                loopedSlides={data.length}

                spaceBetween={0}
                draggable={false}
                grabCursor={false}
                allowTouchMove={false}

                navigation={{
                    nextEl: ".swiperNext",
                    prevEl: ".swiperPrev",
                }}
                modules={[Thumbs, Navigation, Controller]}
                thumbs={{ swiper: thumbsSwiper }}
                controller={{ control: controlledSwiper }}
            >
                {data.map((slide, i) => (
                    <SwiperSlide key={`slide_` + i}>
                        <GatsbyImage image={slide.data} alt={slide.alt} />
                    </SwiperSlide>
                ))}

                <Next className="swiperNext" />
                <Prev className="swiperPrev" />
            </Swiper>

            <ThumbsJacket>
                <Swiper
                    onSwiper={ setThumbsSwiper && setControlledSwiper }
                    loop={true}
                    loopedSlides={data.length}
                    
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    modules={[Thumbs, Navigation, Controller]}
                >
                    {thumbData.map((slide, i) => (
                        <SwiperSlide key={`slide_` + i}>
                            <GatsbyImage image={slide.data} alt={slide.alt} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </ThumbsJacket>
        </Jacket>
    );
}

export default observer(GallerySlider);