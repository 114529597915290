// Imports
// ------------
import React, { useState } from 'react';
import moment from 'moment';
import { action } from 'mobx';

// Styles
// ------------
import {
    Jacket,
    Label,
    SelectLabelButton,
    SelectLabels,
    DropdownList,
    DropdownItem
} from './styles';

// Component
// ------------
const Dropdown = React.forwardRef((props, ref) => {
    // NOTE • Props
    const { label, onChange, onBlur, dataDates, dataPackages } = props;

    // NOTE • States
    const [currentValue, setCurrentValue] = useState('');
    const [open, setOpen] = useState(false);
    const [isFocus, setIsFocus] = useState(false);

    // NOTE • Functions
    const handleOpen = () => {
        setOpen(!open);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleFocus = action((e) => {
        if(currentValue.length >= 1) {
            setIsFocus(true);
        } else {
            setIsFocus(false);

            return onBlur;
        }
    });

    const handleValueChange = (value) => {
        setCurrentValue(value);
    }

    const handleChange = (value) => {
        handleValueChange(value);
        
        // Call method
        if(onChange) onChange(value);

        // Close, after all tasks are finished
        handleClose();
    }

    const handleClear = action(() => {
        setOpen(false);
        setCurrentValue("");
    });

    return (
        <Jacket isFocus={open}>
            <Label isFocus={currentValue !== "" || open}>{label}</Label>
            
            <SelectLabelButton onClick={handleOpen} className="ch"></SelectLabelButton>

            <SelectLabels>
                <span>{currentValue !== "" ? currentValue : ` `}</span>
                <span role="button" onClick={currentValue ? handleClear : null} className="ch">{currentValue ? `Clear` : `Required`}</span>
            </SelectLabels>

            <DropdownList isVisible={open}>
                {dataDates && 
                    dataDates.map(({dateTime}, i) => {
                        let newDate = moment(dateTime).format('Do MMM YYYY');
                        let newTime = moment(dateTime).format('LT');

                        const value = `${newDate} at ${newTime}`;

                        return (
                            <DropdownItem key={i} onClick={() => handleChange(value)} active={value === currentValue}>
                                {value}
                            </DropdownItem>
                        )
                    })
                }

                {dataPackages &&
                    dataPackages.map(({packageLabel}, i) => {
                        const value = packageLabel;

                        return (
                            <DropdownItem key={i} onClick={() => handleChange(value)} active={value === currentValue}>
                                {value}
                            </DropdownItem>
                        )
                    })
                }
            </DropdownList>
        </Jacket>
    );
});

export default Dropdown;