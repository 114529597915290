// Imports
// ------------
import styled, { css } from 'styled-components';
import { Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 6;
    background-color: ${props => props.theme.bc3};
`;

export const Content = styled.div((props) => css`
    margin-bottom: 6rem;

    &:last-child { margin-bottom: 0rem; }

    h4 {
        text-transform: none;

        color: ${props.theme.bc1};
    }
`);