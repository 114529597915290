// Imports
// ------------
import React, { useRef, useEffect } from 'react';
import BookingForm from './Form';
import gsap from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Styles
// ------------
import { Jacket, ImagesJacket, Image, Topographic } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const DogsBooking = ({ images, dates, packages }) => {
    // NOTE • Refs
    const trigger = useRef();
	const target1 = useRef();
	const target2 = useRef();
	const target3 = useRef();
	const target4 = useRef();
	const target5 = useRef();
	const target6 = useRef();

    // NOTE • Onload - Animate Scroll
    useEffect(() => {
        // NOTE • Variables for scroller
        const scroller = document.querySelector('#scroller');
        const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: false });

        // NOTE • Proxy for scroller to pick up smooth scroll
        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        // NOTE • Actual Transition
        gsap.to(target1.current, {
            x: `-5%`,
            y: `0%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "30% 100%",
                end: "30% 50%",
                scrub: 0.5,
                markers: false,
            },
        });
        
        gsap.to(target2.current, {
            x: `85%`,
            y: `-12%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "40% 100%",
                end: "30% 50%",
                scrub: 0.5,
                markers: false,
            },
        });
        
        gsap.to(target3.current, {
            x: `50%`,
            y: `26%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "40% 100%",
                end: "30% 50%",
                scrub: 0.5,
                markers: false,
            },
        });
        
        gsap.to(target4.current, {
            x: `-5%`,
            y: `55%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "40% 100%",
                end: "30% 50%",
                scrub: 0.5,
                markers: false,
            },
        });
        
        gsap.to(target5.current, {
            x: `45%`,
            y: `70%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "40% 100%",
                end: "30% 50%",
                scrub: 0.05,
                markers: false,
            },
        });

        gsap.to(target6.current, {
            width: `70%`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "40% 100%",
                end: "30% 50%",
                scrub: 0.05,
                markers: false,
            },
        });

        // NOTE • Move markers to correct position
        if (document.querySelector(".gsap-marker-scroller-start")) {
            const markers = gsap.utils.toArray('[class *= "gsap-marker"]');

            bodyScrollBar.addListener(({ offset }) => {
                gsap.set(markers, { marginTop: -offset.y });
            });
        }

        // NOTE • Update positions & timing
        bodyScrollBar.addListener(ScrollTrigger.update);
    }, []);

    return (
        <Jacket padBottom ref={trigger}>
            <Row isExpanded isCollapsed>
                <Column small={12} medium={6}>
                    
                    <ImagesJacket>
                        <Image ref={target1} imageOne>
                            <GatsbyImage image={images[0].data} alt={images[0].alt} />
                        </Image>
                        <Image ref={target2} imageTwo>
                            <GatsbyImage image={images[1].data} alt={images[1].alt} />
                        </Image>
                        <Image ref={target3} imageThree>
                            <GatsbyImage image={images[2].data} alt={images[2].alt} />
                        </Image>
                        <Image ref={target4} imageFour>
                            <GatsbyImage image={images[3].data} alt={images[3].alt} />
                        </Image>
                        <Image ref={target5} imageFive>
                            <GatsbyImage image={images[4].data} alt={images[4].alt} />
                        </Image>
                        <Topographic ref={target6} />
                    </ImagesJacket>

                </Column>
                <Column small={12} medium={5}>
                    <BookingForm dates={dates} packages={packages} />
                </Column>
            </Row>
        </Jacket>
    );
}

export default DogsBooking;