// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, H1 } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)`
    position: relative;
    overflow-x: hidden;
    width: 100vw;
    min-height: 120vh;
    pointer-events: none;

    ${breakup.large` min-height: 160vh; `}
`;

export const TitleJacket = styled.div(p => css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    transform: translateY(35vh);
`);

export const MarqueeJacket = styled.div(p => css`
    position: relative;
    z-index: 1;

    transition: all 1.4s ${p.theme.bezzy};
    transform: translateX(${p.isVisible ? `0` : `50vw`}) translateY(6vh);

    ${breakup.large` transform: translateX(${p.isVisible ? `0` : `50vw`}) translateY(-8vh); `}
`);

export const Title = styled(H1)(p => css`
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateY(50%) translateX(-50%);

    width: 80vw;
    text-align: center;
    color: ${p.theme.bc1};
`);


export const ImageGrid = styled.div(p => css`
    transform: translateY(50vh);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);

    ${breakup.large` grid-template-columns: repeat(8,1fr); `}
`);

export const Item = styled.div(p => css`
    position: relative;
    will-change: transform;
    grid-column: ${p.col};
    grid-row: ${p.row};

    height: 12rem;

    ${breakup.medium` height: 20rem; `}

    img {
        width: 100%;
    }
`);