// Imports
// ------------
import styled from 'styled-components';
import { Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 12;
    height: 40vh;
    background-color: ${props => props.theme.bc3};

    .gatsby-image-wrapper { 
        height: 40vh;
    }
`;
