// Imports
// ------
import React from 'react';
import Seo from '@parts/Seo';
import SmoothScroll from '@parts/SmoothScroll';
import Hero from '@parts/Dogs/Hero';
import GallerySlider from '@parts/Dogs/Gallery';
import GallerySliderMobile from '@parts/Dogs/GalleryMobile';
import DogsContent from '@parts/Dogs/Content';
import Booking from '@parts/Dogs/Booking';
import BookingMobile from '@parts/Dogs/BookingMobile';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { observer } from 'mobx-react-lite';

// Page
// ------
const DogsPage = ({ data }) => {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	return (
		<>
			<Seo meta={data.dogs.seoMeta} />
			
			<SmoothScroll id="scrollContainer">
				<Hero data={data.dogs} />
				
				<div key="galleries">
					{bp.large ? (
						<GallerySlider data={data.dogs.galleryMain} thumbData={data.dogs.galleryThumbs} />
					) : (
						<GallerySliderMobile data={data.dogs.galleryMain} thumbData={data.dogs.galleryThumbs} />
					)}
				</div>

				<DogsContent left={data.dogs.contentLeft} right={data.dogs.contentRight} />
				
				<div key="booking">
					{bp.large ? (
						<Booking images={data.dogs.ctaImages} dates={data.dogs.ctaDates} packages={data.dogs.ctaPackages} />
					) : (
						<BookingMobile images={data.dogs.ctaImages} dates={data.dogs.ctaDates} packages={data.dogs.ctaPackages} />
					)}
				</div>

				<Footer />
			</SmoothScroll>
		</>
	);
}

export default observer(DogsPage);

// GraphQL
// ------
export const query = graphql`
    query DogsQuery {
		dogs: datoCmsDogPage {
			title
			featuredImages {
				alt
				data: gatsbyImageData(
					width: 300
					placeholder: BLURRED
					layout: FULL_WIDTH
				)
			}

            galleryMain: gallery {
				alt
				data: gatsbyImageData(
					width: 2000
					placeholder: BLURRED
					layout: FULL_WIDTH
				)
			}

            galleryThumbs: gallery {
				alt
				data: gatsbyImageData(
					width: 150
					placeholder: BLURRED
					layout: FULL_WIDTH
				)
			}

            contentLeft: leftColumn {
                __typename
                
                contentNode {
                    childMarkdownRemark {
                        html
                    }
                }
            }

            contentRight: rightColumn {
                __typename
                
                ... on DatoCmsParserContent {
                    contentNode {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }

            ctaImages {
				alt
				data: gatsbyImageData(
					width: 400
					placeholder: BLURRED
					layout: FULL_WIDTH
				)
			}

			ctaDates: dates {
				dateTime
			}
			
			ctaPackages: packages {
					packageLabel
			}
			
			seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}
	}
`;