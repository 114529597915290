// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)`
    position: relative;
    z-index: 12;
    height: 50vh;
    background-color: ${props => props.theme.bc2};

    ${breakup.large` height: 90vh; `}

    .gatsby-image-wrapper { 
        height: 50vh;

        ${breakup.large` height: 90vh; `}
    }
`;

export const ThumbsJacket = styled.div((props) => css`
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    z-index: 12;

    height: 16rem;
    width: 100%;

    display: flex;
    align-items: center;
    pointer-events: none;

    .swiper {
        height: 100%;
    }

    .swiper-wrapper {
        padding: 1.2rem;
        align-items: center;
        left: -2.4rem;
    }

    .gatsby-image-wrapper, img {
        transition: all 1s ${props.theme.bezzy};
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        transition: all 1s ${props.theme.bezzy};
        transform: scale(1);
        margin-right: .6rem;
        margin-left: .6rem;

        width: 6rem !important;
        height: 6rem !important;
        
        &:before {
            transition: all .8s ${props.theme.bezzy};
            content: '';
            position: absolute;
            z-index: -1;
            top: 0; left: 0; right: 0; bottom: 0;
            transform: scale(0.9);
            background: ${props.theme.bc1};
        }
    }

    .swiper-slide-active {
        transform: scale(1.4);
        z-index: 12;
        margin-right: 2.4rem;
        margin-left: 2.4rem;

        &:before {
            transform: scale(1.1);
            transition-delay: .3s;
        }
    }
`);



export const Next = styled.div`
    position: absolute;
    top: 0; right: 0; bottom: 0;
    z-index: 999999;
    width: 50%;

    display: none;

    ${breakup.large` display: block; `}
`;

export const Prev = styled.div`
    position: absolute;
    top: 0; left: 0; bottom: 0;
    z-index: 999999;
    width: 50%;

    display: none;

    ${breakup.large` display: block; `}
`;