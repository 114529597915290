// Imports
// ------------
import React, { useRef, useState, useEffect } from 'react';
import Input from '@parts/Input';
import Dropdown from '@parts/Dropdown';
import Button from '@parts/Button';
import axios from 'axios';
import { Row, Column } from '@waffl';
import { observer } from 'mobx-react-lite';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

// Styles
// ------------
import { Jacket, Title, FormJacket, ButtonJacket, Response, Content } from './styles';

// Component
// ------------
const BookingForm = ({ dates, packages }) => {
    // NOTE • MIXPANEL
	const mixpanel = useMixpanel();

    // NOTE • Refs
    // ------
    const nameField = useRef(null);
    const emailField = useRef(null);
    const dateField = useRef(null);
    const dogDateRef = useRef(null);
    const packageField = useRef(null);
    const dogPackageRef = useRef(null);

    // NOTE • States
    // ------
    const [formData, setFormData] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailTest, setEmailTest] = useState('');
	const [responseGood, setResponseGood] = useState(false);
    const [responseError, setResponseError] = useState(false);

    const [dogDate, setDogDate] = useState('');
    const [dogPackage, setDogPackage] = useState('');

    // NOTE • Regex
    // ------
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    // NOTE • Handle Input Change
    // ------
    const handleChange = (e) => {
        if (e.target.type === "email") {
            setEmailTest({ value: e.target.value });
            const test = validEmailRegex.test(emailTest.value);
            setEmailError(!test);
        }

        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleDropdown = (e) => {
        // setFormData({ ...formData, [e.name]: e.value });

        if(e.name === "date") {
            setDogDate(e.value);
        }
        
        if(e.name === "package") {
            setDogPackage(e.value);
        }
    }

    const handleBlur = (e) => {
        if (e.target.type === "email") {
            setEmailTest({ value: e.target.value.trim().toLowerCase() });
            const test = validEmailRegex.test(emailTest.value);
            setEmailError(!test);
        }

        if(!emailError || e.target.value.length == 0) {
            setEmailError(false);
        }
    }

    // NOTE • Periodically correct the email validation
    // ------
    useEffect(() => {
        setTimeout(() => {
            if (emailField.current && emailField.current.matches(':-webkit-autofill')) {
                setEmailTest({ value: emailField.current.value.trim().toLowerCase() });
                const test = validEmailRegex.test(emailTest.value);
                setEmailError(!test);
            }
        }, 1000);
    });

    useEffect(() => {
        if(dogDateRef.current?.value && dogPackageRef.current?.value) {
            setFormData({
                ...formData,
                [dogDateRef.current.name] : dogDateRef.current?.value,
                [dogPackageRef.current.name] : dogPackageRef.current?.value,
            });
        }
	}, [dogDateRef.current?.value, dogPackageRef.current?.value]);


    // NOTE • Handle Form Submission
    // ------
    const handleSubmit = (e) => {
        e.preventDefault();

        function encode(data) {
			return Object.keys(data)
				.map(
					(key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
				)
				.join('&');
		}

        const axiosOptions = {
			url: window.location.href,
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: encode({ 'form-name': e.target.getAttribute('name'), ...formData }),
		};

        // console.dir(axiosOptions);

		axios(axiosOptions)
			.then((response) => {
                setResponseGood(true);

                mixpanel.track(`Dogs booking form submitted`);
    
                setTimeout(() => {
                    setFormData('');
                    setEmailError(false);
                    setEmailTest('');
                }, 3000);
    
                setTimeout(() => {
                    setResponseGood(false);
                }, 4000);
			})
			.catch((err) => {
                setResponseError(true);
    
                setTimeout(() => {
                    setFormData('');
                    setEmailError(false);
                    setEmailTest('');
                }, 3000);
    
                setTimeout(() => {
                    setResponseError(false);
                }, 4000);
            });
    }

    return (
        <Jacket padTop>
            <Row isExpanded>
                <Column small={12} mpad>
                    <Title>Make a<br />booking</Title>
                </Column>
            </Row>

            {responseGood || responseError ? (
                <Response good={responseGood} bad={responseError}>
                    <Content>
                        {responseGood ? `Thank you for booking for a photoshoot with your dog(s). I'll be in touch very soon!` : `Something went wrong, please try again.`}
                    </Content>
                </Response>
            ) : (
                <FormJacket
                    onSubmit={handleSubmit}
                    name='Photoscoper: Dog Photoshoot Booking'
                    method='POST'
                    data-netlify='true'
                    netlify-honeypot='bot-field'
                >
                    <input type='hidden' name='bot-field' />
                    <input type='hidden' name='form-name' value='Photoscoper: Dog Photoshoot Booking' />
                    <input ref={dogDateRef} type='hidden' name='Date' value={dogDate} />
                    <input ref={dogPackageRef} type='hidden' name='Package' value={dogPackage} />

                    <Row isExpanded>
                        <Column small={12} large={6} mpad>
                            <Input
                                ref={nameField}
                                isRequired
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label='Name*'
                                type='text'
                                name='name'
                                value={formData.name ? formData.name : ''}
                            />
                        </Column>
                        <Column small={12} large={6} mpad>
                            <Input
                                ref={emailField}
                                isRequired
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label='Email*'
                                type='email'
                                name='email'
                                emailError={emailError}
                                value={formData.email ? formData.email : ''}
                            />
                        </Column>
                    </Row>

                    <Row isExpanded>
                        <Column small={12} large={6} mpad>
                            <Dropdown
                                ref={dateField}
                                isRequired
                                dataDates={dates}
                                label={`Choose Date*`}
                                onBlur={handleBlur}
                                onChange={(e) => handleDropdown({ name: "date", value: e})}
                            />
                        </Column>
                        <Column small={12} large={6} mpad>
                            <Dropdown
                                ref={packageField}
                                isRequired
                                dataPackages={packages}
                                label={`Choose Package*`}
                                onBlur={handleBlur}
                                onChange={(e) => handleDropdown({ name: "package", value: e})}
                            />
                        </Column>
                    </Row>

                    <Row isExpanded>
                        <Column small={12} mpad>
                            <Input
                                isBig
                                isRequired
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label='Tell me about your dog(s), name, type, temperament, etc*'
                                type='textarea'
                                name='message'
                                value={formData.message ? formData.message : ''}
                            />
                        </Column>
                    </Row>

                    <Row isExpanded>
                        <Column small={12} mpad>
                            <ButtonJacket>
                                <Button
                                    isBtn
                                    isNext
                                    label='Send'
                                    disabled={
                                        !formData.name ||
                                        !formData.email ||
                                        !formData.message
                                    }
                                />
                            </ButtonJacket>
                        </Column>
                    </Row>
                </FormJacket>
            )}
        </Jacket>
    );
}

export default observer(BookingForm);