// Imports
// ------------
import React, { useRef, useEffect } from 'react';
import MarqueeHeadline from '@parts/MarqueeHeadline';
import Scrollbar from 'smooth-scrollbar';
import { GatsbyImage } from 'gatsby-plugin-image';
import { loader, pagetrans } from '@states';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import { Jacket, TitleJacket, Title, MarqueeJacket, ImageGrid, Item } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const DogsHero = ({ data }) => {
    const trigger = useRef();
    const titleRef = useRef();
    
    useEffect(() => {
        // NOTE • Variables for scroller
        const scroller = document.querySelector('#scroller');
        const bodyScrollBar = Scrollbar.init(
            scroller,
            {
                damping: 0.1,
                delegateTo: document,
                alwaysShowTracks: true,
                syncCallbacks: true,
            }
        );

        // NOTE • Proxy for scroller to pick up smooth scroll
        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        setTimeout(() => {
            const gridItems = document.querySelectorAll('.grid_item');

            gridItems.forEach(item => {
                const image = item.querySelector('.grid_item-img');
                const yPercentRandomVal = gsap.utils.random(-200,200);

                gsap.timeline()
                    .addLabel('start', 0)
                    .set(image, {
                        transformOrigin: `300% 50%`
                    }, 'start')
                    .to(image, {
                        ease: 'none',
                        startAt: { rotationZ: -30 },
                        rotationZ: 30,
                        scrollTrigger: {
                            scroller: scroller,
                            trigger: item,
                            start: "top bottom",
                            end: "bottom top",
                            scrub: true,
                            // markers: true,
                        }
                    }, 'start')
                    .to(item, {
                        ease: 'none',
                        yPercent: yPercentRandomVal,
                        scrollTrigger: {
                            scroller: scroller,
                            trigger: item,
                            start: "top bottom",
                            end: "top top",
                            scrub: true,
                            // markers: true,
                        }
                    }, 'start');
            });
        }, 150);

        // NOTE • Move markers to correct position
        if (document.querySelector(".gsap-marker-scroller-start")) {
            const markers = gsap.utils.toArray('[class *= "gsap-marker"]');

            bodyScrollBar.addListener(({ offset }) => {
                gsap.set(markers, { marginTop: -offset.y });
            });
        }


        // NOTE • Update positions & timing
        bodyScrollBar.addListener(ScrollTrigger.update);



        // NOTE • Title position
        function pinElement() {
            const { offset } = bodyScrollBar;

            titleRef.current.style.transform = `translate3d(calc(${offset.x}px), calc(${offset.y}px + 35vh), 0)`;
        }

        bodyScrollBar.addListener(pinElement);
        pinElement();

        // NOTE • Animate the title
        gsap.to(titleRef.current, {
            opacity: `0`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "100% 20%",
                end: "30% 50%",
                scrub: 0.5,
                markers: false,
            },
        });

        // NOTE • Remove title listener
        return () => {
            bodyScrollBar.removeListener(pinElement);
        }
    }, []);

    return (
        <Jacket ref={trigger}>
            <TitleJacket ref={titleRef}>
                <Title>{data.title}</Title>
                <MarqueeJacket isVisible={loader.isComplete && pagetrans.isDefault}>
                    <MarqueeHeadline data="Dog Photoshoots" />
                </MarqueeJacket>
            </TitleJacket>

            <ImageGrid>
                <Item className="grid_item" col="1" row="1">
                    <GatsbyImage image={data.featuredImages[0].data} alt={data.featuredImages[0].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="5" row="1">
                    <GatsbyImage image={data.featuredImages[1].data} alt={data.featuredImages[1].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="3" row="2">
                    <GatsbyImage image={data.featuredImages[2].data} alt={data.featuredImages[2].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="8" row="2">
                    <GatsbyImage image={data.featuredImages[3].data} alt={data.featuredImages[3].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="1" row="3">
                    <GatsbyImage image={data.featuredImages[4].data} alt={data.featuredImages[4].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="4" row="3">
                    <GatsbyImage image={data.featuredImages[5].data} alt={data.featuredImages[5].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="3" row="4">
                    <GatsbyImage image={data.featuredImages[6].data} alt={data.featuredImages[6].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="7" row="4">
                    <GatsbyImage image={data.featuredImages[7].data} alt={data.featuredImages[7].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="2" row="5">
                    <GatsbyImage image={data.featuredImages[8].data} alt={data.featuredImages[8].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="4" row="5">
                    <GatsbyImage image={data.featuredImages[9].data} alt={data.featuredImages[9].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="8" row="5">
                    <GatsbyImage image={data.featuredImages[10].data} alt={data.featuredImages[10].alt} className="grid_item-img" />
                </Item>
                <Item className="grid_item" col="6" row="6">
                    <GatsbyImage image={data.featuredImages[11].data} alt={data.featuredImages[11].alt} className="grid_item-img" />
                </Item>
            </ImageGrid>
        </Jacket>
    );
}

export default observer(DogsHero);