// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, H3, Div, P } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Div)``;

export const Title = styled(H3)(props => css`
    color: ${props.theme.bc1};
    margin-bottom: 3.6rem;
`);

export const Text = styled(P)(props => css`
    color: ${props.theme.white};
`);

export const FormJacket = styled.form(props => css``);

export const ButtonJacket = styled.div(props => css``);

export const Response = styled.div((props) => css`
    position: relative;
    display: flex;

    padding: 2.4rem;
    border: 2px dashed ${props.good ? props.theme.pos : props.theme.neg};

    ${breakup.medium` padding: 3.6rem; `}
`);

export const Content = styled(P)`
    color: ${props => props.theme.white};
`;