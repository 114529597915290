// Imports
// ------------
import React from 'react';
import Parser from '@parts/Parser';
import { Row, Column } from '@waffl';

// Styles
// ------------
import { Jacket, Content } from './styles';

// Component
// ------------
const DogsContent = ({ left, right }) => {
    return (
        <Jacket pad>
            <Row isExpanded>
                <Column small={12} medium={5} pushMedium={1} mpad>
                    {left.map((block, i) => {
                        if(block.__typename == "DatoCmsParserContent") return (
                            <Content key={i}>
                                <Parser content={block.contentNode.childMarkdownRemark.html} />
                            </Content>
                        )
                    })}
                </Column>
                <Column small={12} medium={4} pushMedium={2} mpad>
                    {right.map((block, i) => {
                        if(block.__typename == "DatoCmsParserContent") return (
                            <Content key={i}>
                                <Parser content={block.contentNode.childMarkdownRemark.html} />
                            </Content>
                        )
                    })}
                </Column>
            </Row>
        </Jacket>
    );
}

export default DogsContent;